<template>
  <div>
    <el-carousel :interval="4000" type="card" height="400px" width="100%">
      <el-carousel-item >
        <img src="@/assets/Home/swiper1.jpg" alt="" style="height: 100%; width: 100%" />
      </el-carousel-item>
       <el-carousel-item >
        <img src="@/assets/Home/swiper2.jpg" alt="" style="height: 100%; width: 100%" />
      </el-carousel-item>
      <el-carousel-item >
        <img src="@/assets/Home/swiper3.jpg" alt="" style="height: 100%; width: 100%" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
