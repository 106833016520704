<template>
  <div class="header">
    <div class="logo-text"><img src="../assets/logo.png" alt="" /></div>
    <ul class="nav_tab">
      <li
        v-for="item in tab"
        :key="item.path"
        :class="{ active: activeTab == item.path }"
      >
        <router-link :to="item.path" tag="div" class="tab-item">
          <div>{{ item.name }}</div>
          <div>{{ item.eg }}</div>
        </router-link>
      </li>
      <li>
        <el-dropdown class="tab-item ">
          <div class="el-dropdown-link tab-item " style="font-size: 16px">
            <div style="color: #000000">
              服务支持
            </div>
            <div style="color: #000000">Service</div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>视频培训</el-dropdown-item>
            <el-dropdown-item>售后服务</el-dropdown-item>
            <el-dropdown-item>常见问答</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <li>
        <el-dropdown class="tab-item">
          <div class="el-dropdown-link tab-item " style="font-size: 16px">
            <div style="color: #000000">
              在线商城
            </div>
            <div style="color: #000000">Shop</div>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              ><div style="display: flex; align-items: center" @click="goShop('tb')">
                <img
                  src="@/assets/img/tb.png"
                  alt=""
                  style="width: 40px; height: 40px"
                /><span>淘宝</span>
              </div></el-dropdown-item
            >
            <el-dropdown-item
              ><div style="display: flex; align-items: center" @click="goShop('tm')">
                <img
                  src="@/assets/img/tm.png"
                  alt=""
                  style="width: 40px; height: 40px"
                /><span>天猫</span>
              </div></el-dropdown-item
            >
            <el-dropdown-item
              ><div style="display: flex; align-items: center" @click="goShop('jd')">
                <img
                  src="@/assets/img/jd.png"
                  alt=""
                  style="width: 40px; height: 40px"
                /><span>京东</span>
              </div></el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </li>
      <li :class="{ active: activeTab == '/about' }">
        <router-link to="/about" tag="div" class="tab-item">
          <div>关于我们</div>
          <div>about</div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        { name: "首页", path: "/home", eg: "Home" },
        { name: "汽车产品", path: "/productSever", eg: "Product" },
        { name: "应用案例", path: "/case", eg: "Case" },
      ],
      activeTab: null,
    };
  },
  watch: {
    $route: {
      handler(value) {
        this.activeTab = this.$route.path;
      },
    },
  },
  created() {},
  methods: {
    goShop(tab) {
      let url;
      switch (tab) {
        case "tb":
          url =
            "https://shop109300814.taobao.com/?spm=a230r.7195193.1997079397.2.c6b1253aLRDlSE";
          break;
        case "tm":
          url =
            "https://mantianxingcp.tmall.com/search.htm?spm=a220m.1000858.1000725.3.816255d2eUsvtp&user_number_id=2208088788715&rn=176306e4c1871556688e724eed48a46d&keyword=%D3%C2%B8%E7";
          break;
        case "jd":
          url = "https://mall.jd.com/index-10149562.html?from=pc";
          break;
      }
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 1100px;
  // padding: 0 300px;
  .icon-item {
    display: flex;
    align-items: center;
    // width: 20px;
    // height: 20px;
  }
  .logo-text {
    color: #3894ff;
    font-size: 36px;
    width: 200px;
    img {
      width: 100%;
    }
  }
  .nav_tab {
    list-style: none;
    display: flex;
    li {
      width: 150px;
      color: #101010;
      font-size: 16px;
      cursor: pointer;
      >>> .el-dropdown-link {
        font-size: 16px !important;
      }
      .tab-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    .active {
      color: #3894ff;
    }
  }
}
</style>
