<template>
  <div class="footer">
    <div class="footer-main">
      <div class="item1">
        <h3>东莞市勇哥汽车改装用品有限公司</h3>
        <div>地址：东莞市东城街道牛山社区新锡边润德汽车用品中心6栋29号</div>
        <div>电话：13888888888</div>
        <div>邮箱：121212121@qq.com</div>
        <div></div>
      </div>
      <div class="汽车产品">
        <h3>服务支持</h3>
        <div>汽车扶手</div>
        <div>汽车脚托</div>
        <div>汽车腿托</div>
      </div>
      <div class="item3">
        <h3>企业介绍</h3>
        <div>关于我们</div>
      </div>
      <div class="item4">
        <h3>服务支持</h3>
        <div>视频培训</div>
        <div>售后服务</div>
        <div>常见问答</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  background: #101010;
  width: 100%;
  // height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  .footer-main {
    width: 1200px;
    // height: 100%;
    opacity: 0.9;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: repeat(2, 100px);
    color: #ffffff;
  }
  .item1 {
    grid-column: 1/3;
  }
  h3 {
    line-height: 60px;
  }
  div {
    line-height: 40px;
    cursor: pointer;
    :hover {
      //   color: #3894FF;
    }
  }
}
</style>
