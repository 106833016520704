import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'



Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect:'home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path:'/productSever',
    name: 'ProductSever',
    component: () => import('../views/ProductionSever/ProductionSever.vue')
  },
  {
    path:'/about',
    name: 'About',
    component: () => import('../views/About/About.vue')
  },
  {
    path:'/case',
    name: 'Case',
    component: () => import('../views/Case/Case.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
