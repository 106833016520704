<template>
  <div class="home_production">
    <div v-if="cardStyle == 'left'" class="production_item">
      <div class="content_text">
        <h3>{{ production.name }}</h3>
        <div class="note_text">{{ production.eg }}</div>
        <div class="main_text">xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx</div>
        <div class="more">了解更多</div>
      </div>

      <div class="img-box"><img :src="production.imgSrc" alt="" /></div>
    </div>

    <div v-else class="production_item">
      <div class="img-box"><img :src="production.imgSrc" alt="" /></div>
      <div class="content_text">
        <h3>{{ production.name }}</h3>
        <div class="note_text">{{ production.eg }}</div>
        <div class="main_text">
          xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        </div>
        <div class="more">了解更多</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    cardStyle: {
      type: String,
      defalt: "left",
      require: false,
    },
    production: {
      type: Object,
      defalt: [],
      require: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.home_production {
    margin-bottom: 10px;
    transform: translateY(-.4rem);
    background-color: #fff;
    box-shadow: 0 4px 13px 4px rgba(188,192,188,.5);
    border-radius: .2rem;
  .img-box {
    img {
      width: 300px;
      height: 300px;
    }
  }
  .production_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1000px;
    padding:20px 100px
  }
  .content_text {
    width: 300px;

    .note_text {
      margin: 10px 0 30px 0;
      font-weight: bold;
    }
    .main_text {
      word-wrap: break-word;
      //   word-break: normal;
      width: 100%;
    }
    .more {
      cursor: pointer;
      width: 100px;
      height: 30px;
      line-height: 30px;
      border: 1px solid #3894ff;
      color: #3894ff;
      text-align: center;
      border-radius: 4px;
      margin-top: 50px;
    }
  }
}
</style>
