<template>
  <div class="home">
    <Swiper style="width: 100%"></Swiper>
    <div class="main">
      <HomeProduction
        v-for="(item, index) in production"
        :key="index"
        :cardStyle="item.direction"
        :production="item"
      ></HomeProduction>
    </div>
    <!-- <div class="we_use">
      <h4 style="width: 100%; text-align: center">这些车型能可以用我们</h4>
      <div class="show_use">
        <div class="show_item1 wow slideInLeft test_wow">
          <img src="@/assets/Home/ex/1.jpg" alt="" />
        </div>
        <div class="show_item2 wow slideInLeft test_wow">
          <img src="@/assets/Home/ex/2.jpg" alt="" />
        </div>
        <div class="show_item3 wow slideInLeft test_wow">
          <img src="@/assets/Home/ex/3.jpg" alt="" />
        </div>
        <div class="show_item4 wow slideInLeft test_wow">
          <img src="@/assets/Home/ex/4.jpg" alt="" />
        </div>
        <div class="show_item5 wow slideInLeft test_wow">
          <img src="@/assets/Home/ex/5.jpg" alt="" />
        </div>
        <div class="show_item6 wow slideInLeft test_wow">
          <img src="@/assets/Home/ex/6.jpg" alt="" />
        </div>
      </div>
      <div class="more_box">
        <div class="more">了解更多</div>
      </div>
    </div> -->
    <div class="other_use">
      <h4 style="width: 100%; text-align: center">他们都在使用</h4>
      <div class="other_show_use">
        <div class="show_item1"><img src="@/assets/Home/ex/1.jpg" /></div>
        <div class="show_item2"><img src="@/assets/Home/ex/2.jpg" alt="" /></div>
        <div class="show_item3"><img src="@/assets/Home/ex/3.jpg" alt="" /></div>
        <div class="show_item4"><img src="@/assets/Home/ex/7.jpg" alt="" /></div>
        <div class="show_item5"><img src="@/assets/Home/ex/5.jpg" alt="" /></div>
        <div class="show_item6"><img src="@/assets/Home/ex/6.jpg" alt="" /></div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "@/components/Swiper";
import HomeProduction from "./components/HomeProduction";
export default {
  name: "Home",
  data() {
    return {
      production: [
        {
          imgSrc: require("@/assets/Home/fs.jpg"),
          direction: "left",
          name: "汽车扶手",
          eg:'Car armrest'
        },
        {
          imgSrc: require("@/assets/Home/tt.jpg"),
          direction: "right",
          name: "汽车腿托",
          eg:'Car Leg rest'
        },
        {
          imgSrc: require("@/assets/Home/jt.jpg"),
          direction: "left",
          name: "汽车脚拖",
          eg:'Car foot supporting'
        },
        {
          imgSrc: require("@/assets/Home/tz.jpg"),
          direction: "right",
          name: "汽车头枕",
          eg:'Car headrest'
        },
      ],
    };
  },
  mounted() {
    new this.$wow.WOW().init();
  },
  components: {
    Swiper,
    HomeProduction,
  },
};
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .main {
    // padding: 60px 400px;
    // width: 1100px;
  }
  .we_use {
    background: #eeeeee;
    padding: 30px 0px;
    width: 1200px;
    text-align: center;

    .more_box {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      .more {
        width: 100px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #3894ff;
        color: #3894ff;
        border-radius: 4px;
      }
    }

    .show_use {
      margin-top: 20px;
      // display: grid;
      // grid-template-columns: 300px 300px 300px 300px 300px 300px;
      // grid-template-rows: 500px 500px;
      // grid-gap: 10px;
      // .show_item1 {
      //   grid-column-start: 1;
      //   grid-column-end: 2;
      // }
      // .show_item2 {
      //   grid-column-start: 2;
      //   grid-column-end: 3;
      // }
      // .show_item3 {
      //   grid-column-start: 3;
      //   grid-column-end: 4;
      // }
      // .show_item4 {
      //   grid-column-start: 4;
      //   grid-column-end:5;
      // }
      // .show_item5 {
      //   grid-column-start: 5;
      //   grid-column-end: 6;
      // }
      // .show_item6 {
      //   grid-column-start: 6;
      //   grid-column-end: 7;
      // }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .other_use {
    padding: 30px 0px;
    width: 1100px;
    .other_show_use {
      margin-top: 20px;
      display: grid;
      grid-gap: 30px;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-template-rows: repeat(2, 300px);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
